import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { NewsItem } from './';
import { MarkdownRemark, ImageSource } from '../../types/graphql_types';
import { graphql, useStaticQuery } from 'gatsby';
import { Header } from '../elements';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(20),
  },
  newsSection: {
    margin: theme.spacing(2, 0),
  },
  rowPadding: {
    padding: theme.spacing(4, 0),
  },
  paragraphPadding: {
    padding: theme.spacing(1, 0),
  },
  linkArrow: {
    float: 'left',
    color: 'rgba(0, 0, 0, 0.87);',
  },
  linkText: {
    fontSize: theme.spacing(2.5),
    lineHeight: `${theme.spacing(3)}px`,
    color: 'rgba(0, 0, 0, 0.87);',
    padding: theme.spacing(0.25),
  },
  title: {
    fontSize: theme.spacing(4.25),
    lineHeight: `${theme.spacing(4.5)}px`,
  },
  removePadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const NewsList: React.FunctionComponent = () => {
  const classes = useStyles();
  const markdownQuery = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, limit: 1000) {
        edges {
          node {
            html
            frontmatter {
              url
              title
              ingress
              date(formatString: "DD.MM.YYYY")
              image
            }
          }
        }
      }
      allFile(
        filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" }, sourceInstanceName: { eq: "markdown-news-images" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            name
          }
        }
      }
    }
  `);
  const markdowns: [MarkdownRemark] = markdownQuery.allMarkdownRemark.edges;
  const markdown_preview_images: [ImageSource] = markdownQuery.allFile.edges;

  return (
    <Container className={classes.container} maxWidth="lg">
      <section className={classes.newsSection}>
        <Grid container>
          <Grid item xs={12} className={classes.rowPadding}>
            <Header variant="h1" styling="dark" center={false}>
              Ajankohtaista
            </Header>
          </Grid>
          {markdowns.length > 0 ? (
            markdowns.map((markdown: MarkdownRemark) => {
              const {
                node: {
                  frontmatter: { url, date, title, ingress, image },
                },
              } = markdown;
              const previewImage: ImageSource | undefined = markdown_preview_images.find(
                (markdown_preview_image: ImageSource) => {
                  return markdown_preview_image.node.name === image?.split('.png')[0];
                },
              );
              return (
                <NewsItem
                  url={url}
                  date={date}
                  title={title}
                  ingress={ingress}
                  image={previewImage?.node}
                  key={title}
                />
              );
            })
          ) : (
            <Typography variant="body1">Uutisten haku epäonnistui. Yritä myöhemmin uudelleen.</Typography>
          )}
        </Grid>
      </section>
    </Container>
  );
};

export default NewsList;
