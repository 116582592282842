import React from 'react';
import { Layout } from '../../src/components';
import { NewsPost } from '../../src/components/news/';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

export interface INewsPostTemplateProps {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        url: string;
      };
    };
  };
}

const NewsPostTemplate: React.FunctionComponent<INewsPostTemplateProps> = ({ data }) => {
  const { markdownRemark } = data;
  const { html } = markdownRemark;

  return (
    <>
      {markdownRemark.frontmatter.url === '/ajankohtaista/20-04-2022' && (
        <Helmet>
          <title>Terveystalo lääkäri-chat & reseptinuusinta-chat | Treet</title>
          <meta
            name="description"
            content="Treet-verkkoapteekkipalvelun kautta pääset Terveystalon lääkärin chat-vastaanotolle ilman ajanvarausta. Samoin saatavillasi on myös reseptinuusinta-chat."
          />
        </Helmet>
      )}
      <Layout>
        <NewsPost newsHtml={html} />
      </Layout>
    </>
  );
};

export default NewsPostTemplate;

export const pageQuery = graphql`
  query($url: String!) {
    markdownRemark(frontmatter: { url: { eq: $url } }) {
      html
      frontmatter {
        date(formatString: "DD-MM-YYYY")
        url
      }
    }
  }
`;
