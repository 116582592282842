import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Img from 'gatsby-image';
import { StyledLink } from '../elements';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    padding: theme.spacing(0, 4),
    wordBreak: 'break-word',
  },
  boxContainer: {
    height: '512px',
    width: '100%',
    borderRadius: '9px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 15%)',
    '&:hover': {
      color: '#26609A',
      boxShadow: '0px 8px 10px rgb(0 0 0 / 45%)',
    },
  },
  image: {
    height: '250px',
    width: '100%',
    backgroundColor: '#f3f5f7',
    borderRadius: '9px 9px 0 0',
  },
}));

const NewsItem: React.FunctionComponent<any> = ({ title, url, image, date }) => {
  const classes = useStyles();

  if (!url) {
    return null;
  }

  return (
    <Grid item xs={12} sm={6} md={6} lg={6}>
      <StyledLink key={title} href={url} style={{ textDecoration: 'none' }}>
        <Box className={classes.boxContainer} display="flex" flexDirection="column" justifyContent="flex-start">
          {image ? (
            <Img className={classes.image} fluid={image.childImageSharp.fluid} />
          ) : (
            <div className={classes.image} />
          )}

          <Typography className={classes.title} variant="h3">
            {title}
          </Typography>
          <Typography className={classes.title}>{date}</Typography>
        </Box>
      </StyledLink>
    </Grid>
  );
};

export default NewsItem;
