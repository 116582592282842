import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  container: {},
  backToHomepageLinkSection: {
    margin: theme.spacing(2, 0),
  },
  newsSection: {
    margin: theme.spacing(1, 0),
  },
  rowPadding: {
    padding: theme.spacing(1, 0),
  },
  paragraphPadding: {
    padding: theme.spacing(1, 0),
  },
  backToHomepageLink: {
    textDecoration: 'none',
    clear: 'both',
  },
  linkArrow: {
    float: 'left',
    color: 'rgba(0, 0, 0, 0.87);',
  },
  linkText: {
    fontSize: '20px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.87);',
    marginLeft: '30px',
    marginBottom: theme.spacing(0),
  },
  newsStyles: {
    '& h1': {
      fontSize: '40px',
      lineHeight: '45px',
      fontFamily: 'Cera Round Pro',
      fontWeight: 'normal',
      margin: theme.spacing(6, 0),
      textAlign: 'center',
    },
    '& p': { fontSize: '20px', lineHeight: '26px', fontFamily: 'Cera Round Pro' },
    '& a.gatsby-resp-image-link': {
      pointerEvents: 'none',
    },
  },
}));

export type NewsPostProps = {
  newsHtml: string;
};

export const NewsPost: React.FunctionComponent<NewsPostProps> = props => {
  const classes = useStyles();

  return (
    <Container>
      <section className={classes.backToHomepageLinkSection}>
        <Grid container>
          <Grid item xs={12} className={classes.rowPadding}>
            <Link to="/ajankohtaista" className={classes.backToHomepageLink}>
              <ArrowBackIcon className={classes.linkArrow} />
              <Typography className={classes.linkText}>Kaikki artikkelit</Typography>
            </Link>
          </Grid>
        </Grid>
      </section>

      <section className={classes.newsSection}>
        <Grid container>
          <Grid container item xs={12} className={classes.rowPadding}>
            <Typography
              className={classes.newsStyles}
              component="div"
              dangerouslySetInnerHTML={{ __html: props.newsHtml }}
            ></Typography>
          </Grid>
        </Grid>
      </section>
    </Container>
  );
};

export default NewsPost;
